<template>
  <form @submit.prevent="submitMessage">
    <textarea v-model="message" placeholder="Write your message..." required></textarea>
    <button type="submit">Send</button>
  </form>
</template>

<script>
export default {
  name: 'MessageForm',
  data() {
    return {
      message: ''
    };
  },
  methods: {
    async submitMessage() {
      try {
        const response = await fetch('/api', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ message: this.message }),
        });
        if (response.ok) {
          //const responseData = await response.json();
          await response.json();
          this.$emit('message-submitted', this.message);
          this.message = '';
          // Optionally handle responseData
        } else {
          // Handle errors
          console.error('Error submitting message:', response.status);
        }
      } catch (error) {
        console.error('Error submitting message:', error);
      }
    }
  }
};
</script>

<style scoped>
/* Add form styles here */
</style>
