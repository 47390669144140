<template>
  <div id="app">
    <header>
      <h1>留言板</h1>
    </header>
    <div class="message-board">
      <MessageForm @message-submitted="addMessage" />
      <MessageList :messages="messages" />
    </div>
  </div>
</template>

<script>
import MessageForm from './components/MessageForm.vue';
import MessageList from './components/MessageList.vue';

export default {
  name: 'App',
  components: {
    MessageForm,
    MessageList
  },
  data() {
    return {
      messages: []
    };
  },
  methods: {
    startPolling() {
      this.pollingInterval = setInterval(() => {
        this.fetchMessages();
      }, 30000); // Poll every 30 seconds
    },
    stopPolling() {
      clearInterval(this.pollingInterval);
    },
    async addMessage(newMessage) {
      this.messages.push(newMessage);
      // Optionally handle logic after adding message to local state
    },
    async fetchMessages() {
      try {
        const response = await fetch('/api');
        if (response.ok) {
          const newMessages = await response.json();
          if (newMessages.length > this.messages.length) {
            this.showNotification('New message received', {
              body: 'Click to view the message.',
              // Optional: icon: 'url_to_an_icon.png'
            });
          }
          this.messages = newMessages;
        } else {
          // Handle errors
          console.error('Error fetching messages:', response.status);
        }
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    },
    showNotification(title, options) {
      if (Notification.permission === 'granted') {
        new Notification(title, options);
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            new Notification(title, options);
          }
        });
      }
    }
  },
  mounted() {
    this.fetchMessages();
    this.startPolling();
  },
  beforeUnmount() {
    this.stopPolling();
  }
};
</script>

<style>
body {
  font-family: 'Cursive', sans-serif;
  background-color: #ffe0f0; /* Soft pink */
  color: #2c3e50;
  margin: 0;
  padding: 0;
}

header {
  background-color: #ffb3c1; /* Romantic pink */
  padding: 10px 0;
  text-align: center;
}

h1 {
  margin: 0;
  color: #fff;
  font-size: 3em;
}

.message-board {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

textarea {
  width: 100%;
  border: 1px solid #ffb3c1;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 1em;
  resize: vertical;
}

button {
  background-color: #ffb3c1;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
  border-radius: 4px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #ff809b;
}

.message {
  background-color: #ffccde;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}

</style>
