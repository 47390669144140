<template>
  <div class="message-list">
    <div class="message" v-for="(item, index) in messages" :key="index">
      {{ item.message }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageList',
  props: {
    messages: Array
  }
};
</script>

<style scoped>
/* Add message list styles here */
.message {
  margin-bottom: 0.5em; /* Add space between messages */
}
</style>
